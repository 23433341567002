import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Section from "@atoms/Section/Section";
import Button from "@atoms/Button/Button";
import Text from "@atoms/Text/Text";
import { ModalContext } from "@context/modal";
import MarkdownText from "@atoms/MarkdownText/MarkdownText";

const SBAPCTA: React.FC<any> = ({ content, title, label }) => {
	const { showModal } = useContext(ModalContext);
	const { t } = useTranslation();

	return (
		<Button
			label={label}
			iconArrow
			variant="naked"
			onClick={() => {
				showModal(
					<Section spacingTop="xs">
						<Text>
							<MarkdownText content={content} />
						</Text>
					</Section>,
					{
						title,
					},
				);
			}}
		/>
	);
};

export default SBAPCTA;
