import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import firebase from 'gatsby-plugin-firebase';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import './_SingleAccordion.scss';

function SingleAccordion({ data, comingFrom }) {
    let mod_class = ['SingleAccordion'];
    mod_class = mod_class.join(' ');

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel, question) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (!expanded) {
            firebase.analytics().logEvent('faqClick', {
                question,
                comingFrom,
            });
        }
    };

    const listItems = data?.map(
        ({ id: itemId, question, answer, isMarkdown }, index) => {
            const id = itemId || index;
            return (
                <Accordion
                    key={id}
                    elevation={0}
                    expanded={expanded === 'panel' + id}
                    onChange={handleChange('panel' + id, question)}
                >
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls={`panel${id}a-content`}
                        id={`panel${id}a-header`}
                    >
                        <Title size="ml">{question}</Title>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MarkdownText content={answer} />
                    </AccordionDetails>
                </Accordion>
            );
        }
    );

    return <div className={mod_class}>{listItems}</div>;
}

export default SingleAccordion;
