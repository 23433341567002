import { graphql } from "gatsby";
import React, { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BackgroundImage from "gatsby-background-image";
import { TheAepsyWayLink } from "@components/CMSComponents/links";
import Button from "@atoms/Button/Button";
import Section from "@atoms/Section/Section";
import Title from "@atoms/Title/Title";
import TitleGroup from "@atoms/TitleGroup/TitleGroup";
import Footer from "@components/Footer/Footer";
import SideSection from "@components/SideSection/SideSection";
import Seo from "@components/seo";
import Module from "@atoms/Module/Module";
import Icon from "@atoms/Icon/Icon";
import Text from "@atoms/Text/Text";
import { useTranslation } from "react-i18next";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import Layout from "@components/layout";
import ResponsiveImg from "@atoms/ResponsiveImg";
import SingleAccordion from "@components/SingleAccordion/SingleAccordion";
import Stepper from "@components/Stepper";
import {
	ProviderList,
	ChapterUSP,
	GenericIcon,
} from "@components/CMSComponents";
import TherapyPricingShortInfo from "@components/TherapyPricingShortInfo";
import { useLocale } from "@hooks";
import { getOnboardingLink } from "@helpers/linkHelpers";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { withLocale } from "@hocs";
import { useIsMobile } from "@hooks/useIsDevice";
import "./ForTherapistsV2.scss";
import { IconCheckmarkCircleOutline } from "../../../atoms/Icon/list";
import SpecialBadge from "@atoms/SpecialBadge/SpecialBadge";
import MarkdownText from "@atoms/MarkdownText/MarkdownText";
import useAnalytics from "@hooks/useAnalytics";
import SBAPCTA from "./SBAPCTA";

const TextHandler = (props) => (
	<Title size="ul" theme="white">
		{props.children}
	</Title>
);

export const query = graphql`
    query($language: String!, $customLangKey: AEPSYCMS_I18NLocaleCode!, $slug: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            b2CPages(locale: $customLangKey, filters: {
				slug: {
				eq: $slug
				}
			}) {
				seo {
					title
					description
				}
				modules {
					... on AEPSYCMS_ComponentAepsyUiModule {
						layout {
							type
							version
							variant
						}
						name
						texts {
							content
						}
						buttons {
							content
							externalLink
							internalLink {
								b2cPage {
									slug
									locale
								}
								markdownContent {
									content
									name
								}
							}
						}
						badges {
							content
							color
							shade
						}
						icons {
							id
							icon
							color
							shade
						}
						media {
							src {
								url
								width
								height
							}
							breakpoint
						}
						groups {
							id
							texts {
								content
							}
							buttons {
								content
								externalLink
								internalLink {
									b2cPage {
										slug
										locale
									}
									markdownContent {
										content
										name
									}
								}
							}
							badges {
								content
								color
								shade
							}
							icons {
								id
								icon
								color
								shade
							}
							media {
								src {
									url
									width
									height
								}
								breakpoint
							}
						}
					}
					... on AEPSYCMS_ComponentAepsyUiLinkedMarkdown {
						__typename
						content {
							content
						}
					}
					... on AEPSYCMS_ComponentAepsyUiLinkedFaq {
						__typename
						content {
							qar {
								answer
								question
								id
							}
						}
					}
					... on AEPSYCMS_ComponentAepsyUiLinkedComponent {
						__typename
						id
						name
						content {
							name
							component {
							... on AEPSYCMS_ComponentAepsyUiModule {
								layout {
									type
									version
									variant
								}
								texts {
									content
								}
								buttons {
									content
									externalLink
									internalLink {
										b2cPage {
											slug
											locale
										}
										markdownContent {
											content
											name
										}
									}
								}
								badges {
									content
									color
									shade
								}
								icons {
									id
									icon
									color
									shade
								}
								media {
									src {
										url
										width
										height
									}
									breakpoint
								}
								groups {
									id
									texts {
										content
									}
									buttons {
										content
										externalLink
										internalLink {
											b2cPage {
												slug
												locale
											}
											markdownContent {
												content
												name
											}
										}
									}
									badges {
										content
										color
										shade
									}
									icons {
										id
										icon
										color
										shade
									}
									media {
										src {
											url
											width
											height
											}
											breakpoint
										}
									}
								}
							}
						}
					}
				}
			}
        }
        forTherapistsDesktopHero: file(
            relativePath: {
                eq: "for-therapists/header-for-provider-desktop.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        forTherapistsMobileHero: file(
            relativePath: {
                eq: "for-therapists/header-for-provider-mobile.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 450, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const ForTherapistsV2: React.FC<any> = ({ location, data }) => {
	const isMobile = useIsMobile();

	const { t } = useTranslation();
	const { logFirebaseEvent } = useAnalytics();
	const {
		currentLanguage,
		getAppLinkWithCurrentLang,
		routeCmsLocaleToAppLocale,
	} = useLocale();

	const onOnboardingLinkClicked = () => {
		// Only for AdWords
		if (typeof window !== "undefined") {
			(window as any).gtag("event", "lead_therapist", {
				send_to: "AW-482685496/MLw8CMKUhOwCELjklOYB",
			});
		}

		logFirebaseEvent("sign_up_google_form_button_clicked");
	};

	const renderExtraButton = (item: any) => {
		if (item?.buttons?.[0]?.internalLink?.markdownContent) {
			return (
				<SBAPCTA
					content={item?.buttons?.[0]?.internalLink?.markdownContent?.content}
					title={item?.buttons?.[0]?.internalLink?.markdownContent?.name}
					label={item?.buttons?.[0]?.content}
				/>
			);
		}

		if (item?.buttons?.[0]?.externalLink) {
			return (
				<Button
					label={item?.buttons?.[0]?.content}
					externalLink={item?.buttons?.[0]?.externalLink}
					iconArrow
					variant="naked"
				/>
			);
		}

		return <></>;
	};

	const pageData = data?.aepsyCms?.b2CPages?.[0];

	if (!pageData) {
		return <></>;
	}

	const header = pageData.modules.find(
		(module) =>
			module?.layout?.type === "fullscreen" && module?.layout?.version === 3,
	);
	const usp = pageData.modules.find(
		(module) =>
			module?.layout?.type === "collection" && module?.layout?.version === 2,
	);
	const uspList = usp?.groups || [];
	const markdown = pageData.modules.find(
		(module) => module.__typename === "AEPSYCMS_ComponentAepsyUiLinkedMarkdown",
	)?.content?.content;
	const providerListData = pageData.modules.find(
		(module) =>
			module?.layout?.type === "carousel" && module?.layout?.version === 2,
	);
	const allInOne = pageData?.modules.find(
		(module) =>
			module?.layout?.type === "split" && module?.layout?.version === 5,
	);

	const featureUsps = pageData.modules.find(
		(module) =>
			module?.layout?.type === "collection" && module?.layout?.version === 3,
	);

	const uspLayout3 = pageData.modules.find(
		(module) =>
			module?.layout?.type === "collection" && module?.layout?.version === 4,
	);

	const selfEmployment = pageData.modules.find(
		(module) =>
			module.content?.component?.[0]?.layout?.type === "split" &&
			module.content?.component?.[0]?.layout?.version === 3,
	);

	const selfEmploymentData = selfEmployment?.content?.component?.[0];

	const counselling = pageData?.modules.find(
		(module) =>
			module?.layout?.type === "split" &&
			module?.layout?.version === 5 &&
			module?.layout?.variant === "reversed",
	);
	const benefits = pageData.modules.find(
		(module) =>
			module.content?.component?.[0]?.layout?.type === "collection" &&
			module.content?.component?.[0]?.layout?.version === 5,
	)?.content?.component?.[0];

	const joinAsProivder = pageData?.modules.find(
		(module) =>
			module?.layout?.type === "hybrid" && module?.layout?.version === 2,
	);

	const faqData = pageData.modules.find(
		(module) => module.__typename === "AEPSYCMS_ComponentAepsyUiLinkedFaq",
	)?.content?.qar;

	return (
		<Layout>
			<Seo
				title={pageData.seo?.title}
				description={pageData.seo?.description}
			/>
			<div className="ForTherapistsV2 global_theme-green">
				<div className="Header--wrapper">
					<div className="Header--VideoWrapper">
						<HeaderNav
							location={location.pathname}
							theme="white"
							signUpAsProviderShown
						/>
						<div className="ForTherapistsV2--greenBg" />
						<BackgroundImage
							Tag="section"
							id="media-test"
							className="ForTherapistPage--bg"
							fluid={
								isMobile
									? data.forTherapistsMobileHero.childImageSharp.fluid
									: data.forTherapistsDesktopHero.childImageSharp.fluid
							}
						/>

						<div className="Header--title">
							<Title
								tag="h1"
								font="alt"
								size="xxl"
								theme="white"
								align="center"
								noMargin
							>
								{header?.texts?.[0]?.content}
							</Title>
							<Text tag="h2" theme="white" size="s" align="center" noMargin>
								{header?.texts?.[1]?.content}
							</Text>

							<div className="Header--cta">
								<AnchorLink offset={() => 120} href="#provider-signup">
									<Button
										label={t("join.as.psychologist.cta.label")}
										align="center"
										theme="pink-gradient"
										isMobileFullsize
									/>
								</AnchorLink>
								<AnchorLink offset={() => 120} href="#learn-more">
									<Button
										theme="white"
										label={t("learn.more")}
										align="center"
										variant="naked"
									/>
								</AnchorLink>
							</div>
						</div>
					</div>
				</div>

				<Section
					theme="dark"
					container="large"
					className="ForTherapistsV2--usp-wrapper"
				>
					<div className="ForTherapistsV2--usp" id="learn-more">
						{uspList?.map((item, index) => (
							<div key={index} className="ForTherapistsV2--usp-item">
								<Module radius="l" highlighted>
									<Section spacingBottom="s">
										<Icon
											icon={<GenericIcon icon={item?.icons?.[0]?.icon} />}
											size="l"
											theme="green-3"
										/>
									</Section>
									<Title size="ml" tag="h3">
										{item?.texts?.[0]?.content}
									</Title>
									<Text size="s">{item?.texts?.[1]?.content}</Text>
								</Module>
							</div>
						))}
					</div>
				</Section>

				<Section spacing="xs" theme="dark" container="large">
					{markdown && (
						<div className="ForTherapistsV2--story">
							<MarkdownText content={markdown} textHandler={TextHandler} />
						</div>
					)}

					<Section spacingTop="s">
						<TheAepsyWayLink
							buttonProps={{
								theme: "pink-gradient",
							}}
							label="for.providers.button.to.aepsy.way"
						/>
					</Section>
				</Section>
				<div className="ProviderList--wrapper">
					<Section spacingBottom="s" theme="dark">
						<ProviderList
							variant="detail"
							theme="white"
							title={providerListData?.texts?.[0]?.content}
							subtitle={providerListData?.texts?.[1]?.content}
							providerList={providerListData?.groups?.map((item) => ({
								badgeLabel: item?.badges?.[0]?.content,
								badgeColor: item?.badges?.[0]?.color,
								quote: item?.texts?.[0]?.content,
								name: item?.texts?.[1]?.content,
								yearsOfExp: item?.texts?.[2]?.content,
								media: {
									url: item?.media?.[0]?.src?.url,
								},
							}))}
							extraCard={{
								title: providerListData?.texts?.[2]?.content,
								ctaLabel: providerListData?.buttons?.[0]?.content,
								ctaAnchorLink: "#provider-signup",
							}}
						/>
					</Section>
				</div>
				<Section container="large" spacingTop="m">
					<div className="ForTherapistsV2--titleGroup">
						<Title tag="h3" size="xl" align="center" font="alt" noMargin>
							{allInOne?.texts?.[0]?.content}
						</Title>
						<Title tag="h3" size="xl" theme="grey" align="center">
							{allInOne?.texts?.[1]?.content}
						</Title>
					</div>
				</Section>
				<Section spacingBottom="xs">
					<div className="ForTherapistsV2--allInOne">
						<ChapterUSP
							uspList={allInOne?.groups?.map((item) => ({
								id: item?.id,
								title: item?.texts?.[0]?.content,
								subtitle: item?.texts?.[1]?.content,
								icon: item?.icons?.[0]?.icon,
								images: [
									{
										media: "desktop",
										src: {
											url: item?.media?.find(
												(media) => media.breakpoint === "lg",
											)?.src?.url,
										},
									},
									{
										media: "mobile",
										src: {
											url: item?.media?.find(
												(media) => media.breakpoint === "sm",
											)?.src?.url,
										},
									},
								],
							}))}
						/>
					</div>
				</Section>
				<Section
					theme="light"
					container="large"
					className="ForTherapistsV2--usp-wrapper-mobile"
				>
					<div className="ForTherapistsV2--benefits">
						{featureUsps?.groups?.map((item, index) => (
							<div key={index} className="ForTherapistsV2--benefit-item">
								<Module radius="l" padding="s">
									<Section spacingBottom="xs">
										<Icon
											icon={<GenericIcon icon={item.icons[0]?.icon} />}
											size="s"
										/>
									</Section>
									<Section spacingBottom="xxs">
										<Title size="m" noMargin>
											{item.texts[0]?.content}
										</Title>
									</Section>

									<Text size="s">{item.texts[1]?.content}</Text>
								</Module>
							</div>
						))}
					</div>
				</Section>

				<Section container="large" theme="light" spacing="m">
					<div className="ForTherapistsV2--prevention-title">
						<Title tag="h3" size="xl" font="alt" noMargin>
							{counselling?.texts?.[0]?.content}
						</Title>

						<Title tag="h3" size="xl" theme="grey" noMargin>
							{counselling?.texts?.[1]?.content}
						</Title>
					</div>
					<div className="ForTherapistsV2--prevention-items">
						<div className="ForTherapistsV2--prevention-item">
							<SpecialBadge
								title={counselling?.badges?.[0]?.content}
								startSlot={
									<Icon
										icon={<IconCheckmarkCircleOutline />}
										size="s"
										theme="white"
									/>
								}
								theme="pink-gradient"
							/>
							<Module radius="giant" padding="none">
								<div className="ForTherapistsV2--prevention-item-content">
									<Title size="l" noMargin align="center">
										{counselling?.texts?.[2]?.content}
									</Title>
								</div>
								<Section spacing="xs">
									<ResponsiveImg
										desktopImage={
											counselling?.media?.find(
												(media) => media.breakpoint === "lg",
											)?.src?.url
										}
										mobileImage={
											counselling?.media?.find(
												(media) => media.breakpoint === "sm",
											)?.src?.url
										}
									/>
								</Section>
								<div className="ForTherapistsV2--prevention-item-cta">
									<a
										href={getAppLinkWithCurrentLang(
											`${process.env.GATSBY_AEPSY_APP_URL}/the-swiss-insurance-check`,
										)}
										rel="noreferrer"
										target="_blank"
									>
										<Button
											label={counselling?.buttons?.[0]?.content}
											align="center"
											theme="pink-gradient"
											isMobileFullsize
										/>
									</a>
								</div>
							</Module>
						</div>
						<div>
							<Title font="alt" size="ul">
								{counselling?.texts?.[3]?.content}
							</Title>
							<div className="ForTherapistsV2--prevention-accordion">
								<SingleAccordion
									data={counselling?.groups?.map((item) => ({
										id: item.id,
										isMarkdown: false,
										question: item?.texts?.[0]?.content,
										answer: item?.texts?.[1]?.content,
									}))}
								/>
							</div>
						</div>
					</div>
				</Section>
				<Section container="large" theme="soft" spacing="m">
					<Section
						container="tiny"
						spacingBottom="xs"
						className="ForTherapistsV2-headerTitle"
					>
						<Title tag="h3" size="xl" align="center" font="alt" noMargin>
							{uspLayout3?.texts?.[0]?.content}
						</Title>

						<Title tag="h3" size="xl" theme="grey" align="center">
							{uspLayout3?.texts?.[1]?.content}
						</Title>
					</Section>
					<div className="ForTherapistsV2--success-items">
						{uspLayout3?.groups?.map((item, index) => (
							<div key={index} className="ForTherapistsV2--success-item">
								<Module radius="l" padding="m">
									<Title
										size="ul"
										font="alt"
										className="ForTherapistsV2--success-itemTitle"
									>
										{item?.texts?.[0]?.content}
									</Title>
									<Title size="ml">{item?.texts?.[1]?.content}</Title>
								</Module>
							</div>
						))}
					</div>
					<Section spacingTop="s">
						<AnchorLink offset={() => 120} href="#provider-signup">
							<Button
								label={t("join.as.psychologist.cta.label")}
								align="center"
								theme="pink-gradient"
								isMobileFullsize
							/>
						</AnchorLink>
					</Section>
				</Section>

				<Section theme="light" container="large" spacingTop="m">
					<Module
						radius="xl"
						highlighted
						className="ForTherapistsV2--selfEmployment"
						padding="none"
					>
						<SideSection
							title={selfEmploymentData?.texts?.[0]?.content}
							text={selfEmploymentData?.texts?.[1]?.content}
							visual={
								<ResponsiveImg
									desktopImage={
										selfEmploymentData?.media?.find(
											(media) => media.breakpoint === "lg",
										)?.src?.url
									}
									mobileImage={
										selfEmploymentData?.media?.find(
											(media) => media.breakpoint === "sm",
										)?.src?.url
									}
								/>
							}
							link={
								<a
									href={`/${routeCmsLocaleToAppLocale(selfEmploymentData?.buttons?.[0]?.internalLink?.b2cPage?.locale)}/${
										selfEmploymentData?.buttons?.[0]?.internalLink?.b2cPage
											?.slug
									}`}
								>
									<Button
										variant="inline"
										theme="highlighted"
										label={selfEmploymentData?.buttons?.[0]?.content}
										iconArrow
									/>
								</a>
							}
							bgContent={false}
						/>
					</Module>

					<Section spacing="m">
						<Section container="tiny" className="ForTherapistsV2-headerTitle">
							<TitleGroup
								align="desktopCenter"
								title={benefits?.texts?.[0]?.content}
								subtitle={benefits?.texts?.[1]?.content}
							/>
						</Section>
						<div className="ForTherapistsV2--benefits-wrapper">
							<ChapterUSP
								uspList={benefits?.groups.map((item) => ({
									id: item.id,
									title: item?.texts?.[0]?.content,
									subtitle: item?.texts?.[1]?.content,
									icon: "IconCheck",
									images: [
										{
											media: "desktop",
											src: {
												url: item?.media?.find(
													(media) => media.breakpoint === "lg",
												)?.src?.url,
											},
										},
										{
											media: "mobile",
											src: {
												url: item?.media?.find(
													(media) => media.breakpoint === "sm",
												)?.src?.url,
											},
										},
									],
									content: renderExtraButton(item),
								}))}
							/>
						</div>
					</Section>
				</Section>

				<Section spacingBottom="s">
					<TherapyPricingShortInfo
						imageUrlSharp={data.pricingCardImage}
						mobileImageUrlSharp={data.pricingCardMobileImage}
					/>
				</Section>

				<Section container="short" spacingTop="s">
					<TitleGroup
						title={t("for.providers.join.aepsy.section.title")}
						subtitle={t("for.providers.join.aepsy.section.subtitle")}
						align="desktopCenter"
					/>
				</Section>

				<Section spacingBottom="m">
					<Stepper
						list={[
							{
								icon: "👋",
								title: t("for.providers.join.aepsy.section.step.one.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.one.subtitle",
								),
								buttonLabel: t("newsletter.signup.cta"),
								url: getOnboardingLink(currentLanguage),
								onClick: onOnboardingLinkClicked,
							},
							{
								icon: "🗓",
								title: t("for.providers.join.aepsy.section.step.two.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.two.subtitle",
								),
							},
							{
								icon: "📞",
								title: t("for.providers.join.aepsy.section.step.three.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.three.subtitle",
								),
							},
							{
								icon: "👀",
								title: t("for.providers.join.aepsy.section.step.four.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.four.subtitle",
								),
							},
							{
								icon: "🚀",
								title: t("for.providers.join.aepsy.section.step.five.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.five.subtitle",
								),
							},
							{
								icon: "💌",
								title: t("for.providers.join.aepsy.section.step.six.title"),
								subtitle: t(
									"for.providers.join.aepsy.section.step.six.subtitle",
								),
							},
						]}
					/>
				</Section>

				<Section
					container="large"
					className="ForTherapistPage--partOfAepsy"
					spacing="m"
				>
					<div id="provider-signup">
						<Section spacingBottom="xs">
							<Title font="alt" size="xl" theme="white" align="center">
								{joinAsProivder?.texts?.[0]?.content}
							</Title>
						</Section>

						<Section spacingBottom="xs">
							<Module radius="xl" padding="xl">
								<div className="ForTherapistPage--partOfAepsy-content">
									<div className="ForTherapistPage--partOfAepsy-content-column">
										<Title size="ul">
											{joinAsProivder?.texts?.[1]?.content}
										</Title>
										<div className="g_hide_from_tablet_portrait">
											<Section spacingTop="xs">
												<a
													href={getOnboardingLink(currentLanguage)}
													target="_blank"
													rel="noreferrer"
													onClick={onOnboardingLinkClicked}
												>
													<Button
														label={t("join.as.psychologist.cta.label")}
														theme="pink-gradient"
													/>
												</a>
											</Section>
										</div>
									</div>
									<div className="ForTherapistPage--partOfAepsy-content-column">
										<div className="ForTherapistPage--partOfAepsy-content-column-list">
											{joinAsProivder?.groups?.map((item, index) => (
												<div
													key={index}
													className="ForTherapistPage--partOfAepsy-content-column-list-item"
												>
													<Icon
														icon={<IoIosCheckmarkCircle />}
														theme="green-3"
														size="s"
													/>
													<Title size="s" noMargin>
														{item?.texts?.[0]?.content}
													</Title>
												</div>
											))}
										</div>
										<div className="g_show_from_tablet_portrait">
											<Section spacingTop="s">
												<a
													href={getOnboardingLink(currentLanguage)}
													target="_blank"
													rel="noreferrer"
													onClick={onOnboardingLinkClicked}
												>
													<Button
														label={t("join.as.psychologist.cta.label")}
														theme="pink-gradient"
														isMobileFullsize
													/>
												</a>
											</Section>
										</div>
									</div>
								</div>
							</Module>
						</Section>
					</div>
				</Section>

				<Section container="short" spacingTop="m" spacingBottom="m">
					<TitleGroup
						title={t("faq.section.title")}
						subtitle={t("faq.section.subtitle")}
						align="desktopCenter"
					/>
					{faqData && (
						<Section spacing="s">
							<SingleAccordion data={faqData} comingFrom="for-therapists" />
						</Section>
					)}
				</Section>
				<Footer />
			</div>
		</Layout>
	);
};

export default withLocale(ForTherapistsV2);
