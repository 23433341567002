import React from "react";
import Title from "@atoms/Title/Title";
import "./SpecialBadge.scss";

export type SpecialBadgeTheme = "dark" | "pink-gradient";

interface Props {
	title: string;
	startSlot?: React.ReactNode;
	endSlot?: React.ReactNode;
	theme?: SpecialBadgeTheme;
}

const SpecialBadge: React.FC<Props> = ({
	title,
	startSlot,
	endSlot,
	theme = "dark",
}) => {
	return (
		<>
			<div className={`SpecialBadge theme-${theme}`}>
				<div className="SpecialBadge--inner">
					{startSlot && startSlot}
					<Title size="s" noMargin theme="white">
						{title}
					</Title>
					{endSlot && endSlot}
				</div>

				<div className={`SpecialBadge--corner theme-${theme}`} />
			</div>
		</>
	);
};

export default SpecialBadge;
